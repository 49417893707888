import React from "react";
import { createRoot } from "react-dom/client";

import NoteButton from "./NoteButton";
import MatchingList from "./MatchingList";

class App extends React.Component {
  constructor(props, context) {
    super(props, context);

    this._onNoteClicked = this._onNoteClicked.bind(this);

    this.state = {
      notes: new Map(),
      allScales: null,
      selectionMask: 0,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const notes = new Map();
    // TODO: use initializer new Map([[a,b],[c,d]])
    /* eslint-disable no-multi-spaces */
    notes.set("C", 0b100000000000);
    notes.set("C#", 0b10000000000);
    notes.set("D", 0b1000000000);
    notes.set("D#", 0b100000000);
    notes.set("E", 0b10000000);
    notes.set("F", 0b1000000);
    notes.set("F#", 0b100000);
    notes.set("G", 0b10000);
    notes.set("G#", 0b1000);
    notes.set("A", 0b100);
    notes.set("A#", 0b10);
    notes.set("B", 0b1);
    /* eslint-enable no-multi-spaces */
    this.setState({ notes });

    fetch("/scale-masks.json")
      .then((response) => response.json())
      .then((allScales) => {
        this.setState({
          allScales,
        });
      })
      .catch((ex) => {
        console.error("parsing failed", ex); // eslint-disable-line no-console
      });
  }

  _onNoteClicked(name, mask, checked) {
    const originalSelectionState = this.state.selectionMask;
    const { allScales } = this.state;
    let newSelectionState;

    if (checked) {
      newSelectionState = originalSelectionState | mask;
    } else {
      newSelectionState = originalSelectionState ^ mask;
    }

    Object.keys(allScales).forEach((scaleType) => {
      allScales[scaleType].forEach((scale) => {
        scale.matched = newSelectionState === (scale.mask & newSelectionState);
      });
    });

    this.setState({
      selectionMask: newSelectionState,
      allScales,
    });
  }

  render() {
    // TODO: use the map to iterate instead of converting to an array
    const notes = [...this.state.notes.entries()];

    const offset = notes.length / 2;

    const noteButtons = [0, 1].map((group) => {
      const currentOffset = group * offset;

      const noteButtonsInGroup = notes
        .slice(currentOffset, currentOffset + offset)
        .map((note) => (
          <NoteButton
            key={note[0]}
            name={note[0]}
            // note[1] is the mask....we no longer need it
            mask={note[1]}
            pressed={(() => {
              return this.state.selectionMask & note[1];
            })()}
            onNoteClicked={this._onNoteClicked}
          />
        ));

      return (
        <div key={group} className="note-entry-group">
          {noteButtonsInGroup}
        </div>
      );
    });
    let matchingList = null;
    if (this.state.selectionMask) {
      matchingList = <MatchingList scales={this.state.allScales} />;
    }

    return (
      <div>
        <div className="note-entry">{noteButtons}</div>
        {matchingList}
      </div>
    );
  }
}

const root = createRoot(document.getElementById("app"));
root.render(<App />);
