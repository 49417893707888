// import React, { useEffect, useRef, useState } from 'react';
import React from 'react';

function MatchingList({ scales }) {
  const list = Object.keys(scales).map((scaleType) => {
    const scalesOfScaleType = scales[scaleType]
      .filter((scale) => scale.matched)
      .map((scale) => (
        <li key={scale.name} className="matching-scale">
          <div className="matching-scale-name">{scale.name}</div>
          <div className="matching-scale-notes">{scale.notes.join(' - ')}</div>
        </li>
      ));

    return (
      <div key={scaleType}>
        <ul className="matching-scale-list">
          <li className="matching-scale-list-header">{scaleType}</li>
          {scalesOfScaleType}
        </ul>
      </div>
    );
  });

  return <div>{list}</div>;
}

export default MatchingList;
