// import React, { useEffect, useRef, useState } from 'react';
import React from "react";
import classNames from "classnames";

function NoteButton({ name, mask, pressed, onNoteClicked }) {
  const onButtonClick = () => {
    const wasPressed = !pressed;
    // this.setState({
    //   pressed: wasPressed,
    // });

    onNoteClicked(name, mask, wasPressed);
  };

  const btnClass = classNames({
    "btn-note": true,
    "btn-note-pressed": pressed,
  });
  return (
    <button className={btnClass} onClick={onButtonClick}>
      {name}
    </button>
  );
}

export default NoteButton;
